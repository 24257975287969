import { Access, AccessMap, BaseAccess } from '@/plugins/phoenix/types';
import { EntityStates, IAppWidget, ICountry, IProcessingForm, IProduct } from '../system/types';

export enum SessionState {
  Loading = 'LOAD',
  WaitAuth = 'WAIT',
  NotInitialized = 'INIT',
  PreAuthorized = 'PREAUTH',
  Authorized = 'NORMAL',
}

export class WalletmelonAccess extends BaseAccess {
  public static WalletAccountsCurrent = new Access('wallet.accounts.current')
  public static WalletAccountsProcessing = new Access('wallet.accounts.processing')
  public static WalletAccountsReserves = new Access('wallet.accounts.reserves')
  public static WalletStatement = new Access('wallet.statement')
  public static WalletStatementDownload = new Access('wallet.statement.download')
  public static WalletTransactions = new Access('wallet.transactions')
  public static WalletTransactionsDownload = new Access('wallet.transactions.download')
  public static WalletTransactionsReceive = new Access('wallet.transactions.receive')
  public static WalletTransactionsSend = new Access('wallet.transactions.send')
  public static WalletTransactionsExchange = new Access('wallet.transactions.exchange')
  public static WalletProcessing = new Access('wallet.processing')
  public static WalletProcessingLive = new Access('wallet.processing.live')
  public static WalletProcessingDownload = new Access('wallet.processing.download')
  public static WalletProcessingPOS = new Access('wallet.processing.POS')
  public static WalletSettingsCurrency = new Access('wallet.settings.currency')
  public static WalletSettingsTerminals = new Access('wallet.settings.terminals')
  public static WalletQuestionnaire = new Access('wallet.questionnaire')
  public static WalletIdentification = new Access('wallet.identification')
}

export enum User2FAModes {
  None = 'not',
  Logon = 'lgn',
  Sign = 'sgn',
  LogonAndSign = 'all',
}

export interface IUserCustomer {
  userId?: number
  userUID: string
  userRoleCode: string
  customerNumber: string
  customerName?: string
  userProfileType: string
  isPrimary: boolean
}

export interface IUser {
  userUID: string | null
  userLogin: string
  name: string
  userFullName: string
  userPhone: string
  userEmail: string
  userHomePage: string
  rowsPerPage: number
  userBaseCurrency: string
  userDarkTheme: boolean
  checkLogon2FA: boolean
  checkOper2FA: boolean
  askSetup2FA: boolean
  mode2Fa: User2FAModes
  emailVerified: boolean
  identComplete: boolean
  moneySourceVerified: boolean
  userStartupStep: number
  userLocked: boolean
  userLanguage: string
  customerNumber: string
  personNumber: string
  userLastLogin: Date
  userLastIP: string
  userProfileType: string
  processing: boolean
  linkedCustomers: Array<IUserCustomer>
}

export interface IMultifactor {
  multiFactorId: number;
  multiFactorNumber: string;
  multiFactorProvider: string;
  multiFactorMasterKey: string;
  multiFactorSecret: string;
  multiFactorState: EntityStates;
  barCode: string;
}

export interface IOTP {
  code: string;
  factorId: number | null;
  provider: string;
}

export interface IGlossaries {
  countries: Array<ICountry>
  products: Array<IProduct>
  identLevels: Array<any>
  processingForms: Array<IProcessingForm>
}
export interface IAppEnvironmet {
  glossaries: IGlossaries
  translates: Record<string, string>
  widgets: Array<IAppWidget>
  serverVersion: String
  serverInstance: String
}

export interface IAuthorisation {
  userLogin: string;
  userPwd: string;
}

export interface IRegistration {
  userFirstName: string;
  userLastName: string;
  userLanguage: string;
  userLogin: string;
  userPwd: string;
  customerCountry: string;
  mailing: boolean;
  phone: string;
}

export interface IPasswordChange {
  oldPwd: string;
  pwd: string;
}

export interface ISignupToken {
  mrkey: string;
  clid: string;
  login: string;
  lang: string;
  fName: string;
  lName: string;
  legalNum: string;
}

export interface ISignUp {
  userFirstName: string;
  userLastName: string;
  userLanguage: string;
  userLogin: string;
  userPwd: string;
  customerNumber: string | null;
  token: ISignupToken | null;
}

export interface INotification {
  message: string;
  type: 'info' | 'warn';
  timeout: number
}

import { IGlossary, IStringGlossaryItem, StringGlossary } from '@/plugins/phoenix/glossaries';
import { system } from '@/plugins/store';
import { DEF_STRING_ITEM } from '../store';

export class ProcessingFormsGlossary implements IGlossary<string, IStringGlossaryItem> {
  name: string = 'ProcessingForms'
  loaded = false
  list: Array<IStringGlossaryItem> = [];

  get map(): Record<string, IStringGlossaryItem> {
    let map: Record<string, IStringGlossaryItem> = {};
    this.items.forEach(item => {
      map[item.code] = item
    });
    return map;
  }

  get items(): IStringGlossaryItem[] {
    console.log(system.processingForms);
    if (system.processingForms.length !== 0 && !this.loaded) {
      this.loaded = true;
      this.reload();
    }
    return this.list
  }

  item(code: string, def: IStringGlossaryItem | null = null): IStringGlossaryItem {
    return this.map[code] || (def || Object.assign({ code }, DEF_STRING_ITEM));
  }

  async reload() {
    system.processingForms.forEach(form => {
      this.list.push({
        code: form.processingFormCode,
        label: 'globals.lists.processingForms.' + form.processingFormCode,
        icon: form.processingFormIcon,
        color: null,
        textColor: null,
      });
    })
  }
}

const glossary = new ProcessingFormsGlossary();
export default glossary;

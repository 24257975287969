import { system } from '@/plugins/store';
import $ph from '@/plugins/phoenix';
import { IGlossary } from '@/plugins/phoenix/glossaries';
import { ILink, IView, MenuItem } from '@/plugins/phoenix/types';
import { IPhoenixModule } from '@/plugins/phoenix/library';
import Vue from 'vue';
import { IFilter, ITableOptions } from './types';
import { TABLE_OPTIONS_DEFAULT } from './store';

// Glossaries
import listProcessStates from './glossaries/processStates';
import listEntityStates from './glossaries/entityStates';
import listCountries from './glossaries/countries';
import listProducts from './glossaries/products';
import listLanguages from './glossaries/languages';
import listProcessingForms from './glossaries/processingForms';

// Components
Vue.component('wml-loader', () => import(/* webpackChunkName: "baseTools" */ './components/wml-loader.vue'))
Vue.component('wml-button', () => import(/* webpackChunkName: "baseTools" */ './components/wml-button.vue'))
Vue.component('wml-base-card', () => import(/* webpackChunkName: "baseTools" */ './components/wml-base-card.vue'))
Vue.component('wml-base-group', () => import(/* webpackChunkName: "baseTools" */ './components/wml-base-group.vue'))
Vue.component('wml-notification', () => import(/* webpackChunkName: "baseTools" */ './components/wml-notification.vue'))
Vue.component('wml-base-alert', () => import(/* webpackChunkName: "baseTools" */ './components/wml-base-alert.vue'))

Vue.component('wml-widget-col', () => import(/* webpackChunkName: "baseTools" */ './components/wml-widget-col.vue'))

Vue.component('wml-currency-icon', () => import(/* webpackChunkName: "baseTools" */ './components/wml-currency-icon.vue'))
Vue.component('wml-pay-method-icon', () => import(/* webpackChunkName: "baseTools" */ './components/wml-pay-method-icon.vue'))
Vue.component('v-line', () => import(/* webpackChunkName: "baseTools" */ './components/v-line.vue'))
Vue.component('v-line-text', () => import(/* webpackChunkName: "baseTools" */ './components/v-line-text.vue'))

Vue.component('v-data-field', () => import(/* webpackChunkName: "dataField" */ './components/v-data-field.vue'))
Vue.component('v-data-area', () => import(/* webpackChunkName: "dataField" */ './components/v-data-area.vue'))
Vue.component('v-data-select', () => import(/* webpackChunkName: "dataField" */ './components/v-data-select.vue'))
Vue.component('v-data-combo', () => import(/* webpackChunkName: "dataField" */ './components/v-data-combo.vue'))
Vue.component('v-multi-select', () => import(/* webpackChunkName: "dataField" */ './components/v-multi-select.vue'))
Vue.component('v-list-state', () => import(/* webpackChunkName: "dataField" */ './components/v-list-state.vue'))
Vue.component('v-cell-check', () => import(/* webpackChunkName: "dataField" */ './components/v-cell-check.vue'))

Vue.component('v-dialog-title', () => import(/* webpackChunkName: "dialogTitle" */ './components/v-dialog-title.vue'))
Vue.component('v-tab-caption', () => import(/* webpackChunkName: "dialogTitle" */ './components/v-tab-caption.vue'))

Vue.component('wml-base-view', () => import(/* webpackChunkName: "baseViews" */ './views/wml-base-view.vue'))
Vue.component('wml-base-list-view', () => import(/* webpackChunkName: "baseViews" */ './views/wml-base-list-view.vue'))
Vue.component('wml-base-entity', () => import(/* webpackChunkName: "baseViews" */ './views/wml-base-entity.vue'))
Vue.component('wml-base-modal', () => import(/* webpackChunkName: "baseViews" */ './dialogs/wml-base-modal.vue'))

let moduleSystem: IPhoenixModule = {
  name(): string {
    return 'system'
  },

  routes(): Array<ILink> {
    return [];
  },

  userMenu(): Array<MenuItem> {
    return [];
  },

  appMenu(): Array<MenuItem> {
    return [];
  },

  toolbar(): Array<MenuItem> {
    return [];
  },

  reports(): Array<IView> {
    return [];
  },

  async init(): Promise<any> {
    const glossaries: Array<IGlossary<any, any>> = []

    glossaries.push(listProcessStates)
    glossaries.push(listEntityStates)
    glossaries.push(listCountries)
    glossaries.push(listProducts)
    glossaries.push(listLanguages)
    glossaries.push(listProcessingForms)

    $ph.setup({ glossaries })
  },
}

export default moduleSystem;

// -------------------------------------------------------------------------------------------------------------------------------------------------------
// -- HELPERS
// -------------------------------------------------------------------------------------------------------------------------------------------------------

export function prepareFilter<T extends IFilter>(filter: T): T {
  const newFilter: T = $ph.clone(filter);
  newFilter.limit = filter.limit && filter.limit > 0 ? filter.limit : system.tableRows;
  return newFilter;
}

export function updateFilter<T extends IFilter>(filter: T, table: ITableOptions): T {
  const newFilter: T = $ph.clone(filter);
  newFilter.limit = table.itemsPerPage === -1 ? 2147483640 : table.itemsPerPage;
  newFilter.offset = (table.page - 1) * table.itemsPerPage;
  newFilter.sortBy = table.sortBy && table.sortBy.length > 0 ? table.sortBy[0] : null;
  newFilter.sortOrder = table.sortDesc && table.sortDesc.length > 0 && table.sortDesc[0] ? 'DESC' : 'ASC';
  return newFilter;
}

export function prepareTable(table: ITableOptions, filter: IFilter): ITableOptions {
  let newOpts;
  if (table) {
    newOpts = $ph.clone(table);
  } else {
    newOpts = TABLE_OPTIONS_DEFAULT;
  }
  const limit = filter.limit;
  newOpts.itemsPerPage = limit;
  newOpts.page = filter.offset / limit + 1;
  newOpts.sortBy = filter.sortBy ? [filter.sortBy] : [];
  newOpts.sortDesc = filter.sortOrder === 'DESC' ? [true] : [false];
  return newOpts;
}

export function prepareObject(item: any): any {
  item.objectCreated = item.objectCreated || new Date()
  item.objectEdited = item.objectEdited || new Date()
  return item;
}

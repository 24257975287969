import $ph from '@/plugins/phoenix'
import { AppError, clone } from '@/plugins/phoenix/library';
import settings from '@/plugins/settings';
import { session, system } from '@/plugins/store';
import Vue from 'vue';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { currency, prepareAccount, prepareCustomer, prepareOperation, prepareProcessing, prepareTransaction } from '.';
import { IMultifactor, IUser, IUserCustomer } from '../session/types';
import { prepareFilter } from '../system';
import { CardTokenTypes, EntityStates, ISearchResult, ProcessStates, ReportFormats } from '../system/types';
import { AccountStates, AccountTypeModes, CurrencyType, DiscretizationTypes, IAccount, ICardToken, IChange2FA, ICompanyData, ICurrency, ICustomer, ICustomerProcessingForm, ICustomerQuestionnaire, IDashboardItem, IDashboardItemChangeRequest, IDataPreview, IIdentLevel, INetworkAddress, INetworkSettings, IOperation, IOperationFull, IOperFilter, IOperModeSettings, IOperTypeSettings, IPayMethod, IPayMethodSettings, IPayMethodType, IPerson, IProcessingFilter, IProcessingRequest, IProcessingRequestBase, IRate, IRateRequest, IRatesHistory, IReport, IReportFilter, IStatement, IStatementFilter, ITransaction, ITransFilter, IVerification, IVerificationIdent, OperTypeModes, PaymentMethodType, RedirectModes, Replace, ROW_NUMBER_END_BAL, ROW_NUMBER_START_BAL, ROW_NUMBER_TURNS, StatementDateType, Urgency } from './types';

// -------------------------------------------------------------------------------------------------------------------------------------------------------
// -- DEFAULTS
// -------------------------------------------------------------------------------------------------------------------------------------------------------

export function IDENT_DEFAULT(): IIdentLevel {
  return {
    identLevelId: 0,
    identLevelState: EntityStates.Active,
    identLevelDayIn: 0,
    identLevelMonthIn: 0,
    identLevelDayOut: 0,
    identLevelMonthOut: 0,
    identLevelRedirectMode: RedirectModes.None,
    identLevelComplete: false,
    personLevelState: ProcessStates.None,
    personLevelId: null,
    personLevelUrl: null,
    personLevelToken: null,
    personLevelProvider: null,
    customerTurnDayIn: 0,
    customerTurnMonthIn: 0,
    customerTurnDayOut: 0,
    customerTurnMonthOut: 0,
    customerTopupAllowed: 0,
    customerWithdrawAllowed: 0,
    hasFiatTopup: false,
    hasFiatWithdraw: false,
    hasCryptoGet: false,
    hasCryptoSend: false,
    hasFiatExchange: false,
    hasCryptoExchange: false,
  }
}

export function RATE_DEFAULT(): IRate {
  return {
    currBuy: 'XXX',
    currSell: 'XXX',
    customerRate: 0,
    gateCode: null,
    realRate: 0,
  }
}

export function CURRENCY_DEFAULT(): ICurrency {
  return {
    currencyCode: 'XXX',
    currencyGate: null,
    currencyName: 'NO CURRENCY',
    productCode: 'FIAT',
    currencyPrecision: 0,
    currencyState: EntityStates.Closed,
    currencyType: CurrencyType.Fiat,
    baseRate: RATE_DEFAULT(),
    currencyIcon: null,
    currencyOrder: 0,
  }
}

export function ACCOUNT_DEFAULT(): IAccount {
  return {
    accountCurrency: 'XXX',
    accountBookNumber: '',
    accountTypeCode: 'XXXX',
    productCode: '',
    accountNumber: null,
    accountNumberPublic: '',
    customerNumber: '',
    customerNumberPublic: '',
    accountName: '',
    accountOpened: new Date(),
    accountState: AccountStates.Active,
    accountTypeMode: AccountTypeModes.CustomerCurrent,
    accountGate: null,
    accountBalFin: 0,
    accountBalHold: 0,
    accountBalAvail: 0,
    accountBalAvailEq: 0,
    accountBalMax: null,
    accountBalMin: null,
    accountTurnDayMaxIn: null,
    accountTurnMonthMaxIn: null,
    gateCode: null,
    currency: CURRENCY_DEFAULT(),
    manageByCustomer: true,
    networks: [],
  }
}

export function OPER_DEFAULT(): IOperation {
  return {
    operTypeCode: 'NONE',
    operMainCurrency: settings.company.companyBaseCurrency,
    operValueDate: new Date(),
    productCode: 'CRYP',
    operOutAccount: '',
    operOutCurrency: settings.company.companyBaseCurrency,
    operOutCustomer: '',
    operOutAmount: 0,
    operRate: 1,
    operInAccount: '',
    operInWallet: null,
    operInCurrency: settings.company.companyBaseCurrency,
    operInCustomer: '',
    operInAmount: 0,
    operDescr: '',
    operFeeCurrency: settings.company.companyBaseCurrency,
    operFeeAmount: 0,
    operUrgency: Urgency.Regular,
    operGate: null,
    operGateChannel: null,
    operGateIn: null,
    operGateInChannel: null,
    operGateOut: null,
    operGateOutChannel: null,
    operToken: null,
    payMethod: null,
    operOtpCheck: null,
    waitRedirect: false,
    feeGroup: 'DEFAULT',
    fees: [],
    frontId: 0,
    objectCreated: null,
    operBaseFeeAmount: 0,
    operBookDate: new Date(),
    operNumber: '',
    operPerson: null,
    operState: ProcessStates.Created,
    operMode: OperTypeModes.Any,
    operationCountry: null,
    operationGateRef: null,
    operationGateInRef: null,
    operationGateOutRef: null,
    operBrowserData: null,
  }
}

export function PAY_TYPE_DEFAULT(): IPayMethodType {
  return {
    payMethodFormUI: 'none',
    payMethodMultiCurrency: false,
    payMethodMemoName: '',
    payMethodTypeVariant: 'system',
    payMethodType: PaymentMethodType.Alternative,
    payMethodTypeIcon: '',
    payMethodTypeName: 'SYSTEM',
  }
}

export const FILTER_DEFAULT_OPER: IOperFilter = {
  export: false,
  append: false,
  defaultSearch: null,
  operReference: null,
  customerNumber: null,
  accountNumber: null,
  processState: null,
  operTypeCode: null,
  operCurrency: null,
  amount: { from: null, till: null },
  objectCreated: { from: new Date().native(false), till: new Date().native(false) },
  offset: 0,
  limit: 0,
  node: '',
  total: 0,
  sortBy: 'objectCreated',
  sortOrder: 'DESC',
  nodesInfo: [],
}

export const FILTER_DEFAULT_REPORTS: IReportFilter = {
  export: false,
  append: false,
  defaultSearch: null,
  reportType: null,
  reportFormat: null,
  reportState: null,
  reportCreated: { from: new Date().native(false), till: new Date().native(false) },
  offset: 0,
  limit: 0,
  node: '',
  total: 0,
  sortBy: 'reportCreated',
  sortOrder: 'DESC',
  nodesInfo: [],
}

export const FILTER_DEFAULT_PROCESSING: IProcessingFilter = {
  requestId: null,
  requestState: null,
  requestCurrency: null,
  requestMethod: null,
  requestProcessingType: null,
  requestTerminal: null,
  requestTestMode: false,
  customerNumber: null,
  export: false,
  append: false,
  defaultSearch: null,
  dateType: StatementDateType.Real,
  amount: { from: null, till: null },
  objectCreated: { from: new Date().native(false), till: new Date().native(false) },
  offset: 0,
  limit: 0,
  node: '',
  total: 0,
  sortBy: 'objectCreated',
  sortOrder: 'DESC',
  nodesInfo: [],
}

export const FILTER_DEFAULT_STATEMENT: IStatementFilter = {
  export: false,
  append: false,
  defaultSearch: null,
  accountNumber: null,
  bookingNumber: '',
  bookCurrency: '',
  hideStorno: true,
  dateType: StatementDateType.Real,
  range: { from: new Date().native(false), till: new Date().native(false) },
  offset: 0,
  limit: 0,
  node: '',
  total: 0,
  sortBy: null,
  sortOrder: null,
  nodesInfo: [],
}

export const STATEMENT_DEFAULT: IStatement = {
  accountCurrency: settings.company.companyBaseCurrency,
  accountName: '',
  accountNumber: '',
  accountNumberPublic: '',
  currency: clone(CURRENCY_DEFAULT),
  endBalance: 0,
  turnoverCt: 0,
  turnoverDt: 0,
  startBalance: 0,
  statementFrom: new Date(),
  statementTo: new Date(),
  transactions: [],
}

export const SEARCH_RESULT_DEFAULT: ISearchResult<any> = {
  total: 0,
  limit: 0,
  items: [],
  node: '',
  offset: 0,
  nodesInfo: [],
}

@Module({ name: 'wallet' })
export default class WalletStore extends VuexModule {
  private _company: ICompanyData | null = null
  private _customer: ICustomer | null = null
  private _person: IPerson | null = null
  private _accounts: Array<IAccount> = []
  private _accountIndex: Record<string, IAccount> = {}
  private _currencies: Array<ICurrency> = []
  private _currencyIndex: Record<string, ICurrency> = {}
  private _baseRates: Record<string, IRate> = {}
  private _baseCurrency: ICurrency = CURRENCY_DEFAULT()
  private _baseAccount: IAccount = ACCOUNT_DEFAULT()
  private _identCurrent: IIdentLevel = IDENT_DEFAULT()
  private _identNext: IIdentLevel = IDENT_DEFAULT()
  private _identLevels: Array<IIdentLevel> = []
  private _identShow = false
  private _dashboard: Array<IDashboardItem> = []
  private _dashboardDiscret: DiscretizationTypes = DiscretizationTypes.Month
  private _dashboardBalances: Array<number> = []
  private _history: Record<string, IRatesHistory> = {}
  private _lastTransList: Array<IOperationFull> = []
  private _payMethodsVariants: Array<IPayMethodType> = []
  private _payMethodsVariantsIndex: Record<string, IPayMethodType> = {}
  private _globalPayMethods: Array<IPayMethod> = []
  private _userPayMethods: Array<IPayMethod> = []
  private _currentOperation: IOperation | null = null
  private _currentPreview: IDataPreview = { visible: false }
  private _questionnarire: ICustomerQuestionnaire | null = null

  private operFilter: IOperFilter = clone(FILTER_DEFAULT_OPER);
  private operList: ISearchResult<IOperationFull> = clone(SEARCH_RESULT_DEFAULT);

  private processingFilter: IProcessingFilter = clone(FILTER_DEFAULT_PROCESSING);
  private processingList: ISearchResult<IProcessingRequest> = clone(SEARCH_RESULT_DEFAULT);

  private statementFilter: IStatementFilter = clone(FILTER_DEFAULT_STATEMENT);
  private statementList: IStatement = clone(STATEMENT_DEFAULT);

  private reportsFilter: IReportFilter = clone(FILTER_DEFAULT_REPORTS);
  private reportsList: ISearchResult<IReport> = clone(SEARCH_RESULT_DEFAULT);

  // ---------------------------------------------------------------------- GETTERS
  get company(): ICompanyData | null {
    return this._company
  }

  get customer(): ICustomer | null {
    return this._customer
  }

  get person(): IPerson | null {
    return this._person
  }

  get accounts(): Array<IAccount> {
    return this._accounts
  }

  get accountIndex(): Record<string, IAccount> {
    return this._accountIndex
  }

  get currencies(): Array<ICurrency> {
    return this._currencies
  }

  get baseCurrency(): ICurrency {
    return this._baseCurrency
  }

  get baseAccount(): IAccount {
    return this._baseAccount
  }

  get currIndex(): Record<string, ICurrency> {
    return this._currencyIndex
  }

  get baseRates(): Record<string, IRate> {
    return this._baseRates
  }

  get total(): number {
    const opers: Array<string> = system.balanceAccTypes
    return this._accounts
      .filter(acc => opers.length === 0 || opers.some(op => (op === (acc.accountTypeCode + '/' + acc.productCode)) || (op === (acc.accountTypeCode + '/*'))))
      .reduce((total, acc) => {
        total += acc.accountBalAvailEq
        return total
      }, 0)
  }

  get identCurrent(): IIdentLevel {
    return this._identCurrent
  }

  get identNext(): IIdentLevel {
    return this._identNext
  }

  get identLevels(): Array<IIdentLevel> {
    return this._identLevels
  }

  get isIdentVisible(): boolean {
    return this._identShow;
  }

  get dashboard(): Array<IDashboardItem> {
    return this._dashboard
  }

  get dashboardDiscret(): DiscretizationTypes {
    return this._dashboardDiscret;
  }

  get dashboardBalances(): Array<number> {
    return this._dashboardBalances;
  }

  get lastTransactions(): Array<IOperationFull> {
    return this._lastTransList;
  }

  get globalPayMethods(): Array<IPayMethod> {
    return this._globalPayMethods;
  }

  get userPayMethods(): Array<IPayMethod> {
    return this._userPayMethods;
  }

  get payMethods(): Array<IPayMethod> {
    return this._globalPayMethods.concat(this._userPayMethods);
  }

  get payMethodsVariants(): Array<IPayMethodType> {
    return this._payMethodsVariants;
  }

  get payMethodsVariantsIndex(): Record<string, IPayMethodType> {
    return this._payMethodsVariantsIndex
  }

  get filterOpers(): IOperFilter {
    return prepareFilter(this.operFilter);
  }

  get filterProcessing(): IProcessingFilter {
    return prepareFilter(this.processingFilter);
  }

  get filterStatement(): IStatementFilter {
    return prepareFilter(this.statementFilter);
  }

  get filterReports(): IReportFilter {
    return prepareFilter(this.reportsFilter);
  }

  get reports(): ISearchResult<IReport> {
    return this.reportsList;
  }

  get operations(): ISearchResult<IOperationFull> {
    return this.operList;
  }

  get processing(): ISearchResult<IProcessingRequest> {
    return this.processingList;
  }

  get statement(): IStatement {
    return this.statementList;
  }

  get currentOperation(): IOperation | null {
    return this._currentOperation
  }

  get currentPreview(): IDataPreview {
    return this._currentPreview
  }

  get questionnaire(): ICustomerQuestionnaire | null {
    return this._questionnarire
  }

  get linkedCustomers(): Array<IUserCustomer> {
    return session.user && session.user.linkedCustomers && this.customer
      ? session.user.linkedCustomers.filter(customer => customer.customerNumber !== this.customer!.customerNumber)
      : []
  }

  // ---------------------------------------------------------------------- MUTATIONS
  @Mutation
  setProcessingFilter(value: IProcessingFilter) {
    this.processingFilter = value;
  }

  @Mutation
  setProcessing(value: ISearchResult<IProcessingRequest>) {
    this.processingList = value;
  }

  @Mutation
  addProcessing(value: ISearchResult<IProcessingRequest>) {
    this.processingList.items = [...this.processingList.items, ...value.items]
    this.processingList.limit = value.limit
    this.processingList.node = value.node
    this.processingList.nodesInfo = value.nodesInfo
    this.processingList.offset = value.offset
    this.processingList.total = value.total
  }

  @Mutation
  setReportFilter(value: IReportFilter) {
    this.reportsFilter = value;
  }

  @Mutation
  setReports(value: ISearchResult<IReport>) {
    this.reportsList = value;
  }

  @Mutation
  setOperFilter(value: IOperFilter) {
    this.operFilter = value;
  }

  @Mutation
  setOperations(value: ISearchResult<IOperationFull>) {
    this.operList = value;
  }

  @Mutation
  addOperations(value: ISearchResult<IOperationFull>) {
    this.operList.items = [...this.operList.items, ...value.items]
    this.operList.limit = value.limit
    this.operList.node = value.node
    this.operList.nodesInfo = value.nodesInfo
    this.operList.offset = value.offset
    this.operList.total = value.total
  }

  @Mutation
  setStatementFilter(value: IStatementFilter) {
    this.statementFilter = value;
  }

  @Mutation
  setStatement(value: IStatement) {
    this.statementList = value;
  }

  @Mutation
  setIdentVisible(value: boolean) {
    this._identShow = value;
  }

  @Mutation
  setCompany(value: ICompanyData) {
    this._company = value;
  }

  @Mutation
  setCustomer(value: ICustomer) {
    this._customer = prepareCustomer(value);
  }

  @Mutation
  setQuestionnaire(value: ICustomerQuestionnaire) {
    this._questionnarire = value;
  }

  @Mutation
  setPerson(value: IPerson) {
    this._person = value;
  }

  @Mutation
  setAccounts(value: Array<IAccount>) {
    const index: Record<string, IAccount> = {}
    let base: IAccount = ACCOUNT_DEFAULT()

    value.forEach(acc => {
      acc = prepareAccount(acc)
      acc.currency = this._currencyIndex[acc.accountCurrency]
      if (acc.currency && acc.currency.baseRate.realRate > 0) {
        acc.accountBalAvailEq = acc.accountBalAvail / acc.currency.baseRate.realRate
      } else {
        acc.accountBalAvailEq = 0
      }

      if (acc.accountNumber) {
        index[acc.accountNumber] = acc
      }

      if (acc.accountCurrency === this._baseCurrency.currencyCode && acc.accountTypeMode === 'CA') {
        base = acc
      }
    })

    this._accounts = value
    this._baseAccount = base
    this._accountIndex = index
  }

  @Mutation
  setBaseCurrency(value: ICurrency) {
    this._baseCurrency = value
  }

  @Mutation
  setBaseRates(value: Array<IRate>) {
    const index: Record<string, IRate> = {}
    value.forEach(rate => { if (rate && rate.currSell) { index[rate.currSell] = rate } })
    this._baseRates = index
  }

  @Mutation
  setCurrencies(value: Array<ICurrency>) {
    const index: Record<string, ICurrency> = {}
    value.forEach(curr => {
      curr.baseRate = this._baseRates[curr.currencyCode] || RATE_DEFAULT()
      index[curr.currencyCode] = curr
    })

    value.sort((a, b) => a.currencyCode.localeCompare(b.currencyCode))

    this._currencies = value
    this._currencyIndex = index
  }

  @Mutation
  setIdentCurrent(value: IIdentLevel) {
    this._identCurrent = value
  }

  @Mutation
  setIdentNext(value: IIdentLevel) {
    this._identNext = value
  }

  @Mutation
  setIdentLevels(value: Array<IIdentLevel>) {
    this._identLevels = value
  }

  @Mutation
  setDashboard(value: Array<IDashboardItem>) {
    if (value) {
      value.forEach(item => {
        const current = this._dashboard.find(el => el.dashboardItemId === item.dashboardItemId)
        item.currency = this._currencyIndex[item.currencyCode] || CURRENCY_DEFAULT
        if (item.accountNumber) {
          item.account = this._accountIndex[item.accountNumber] || null
        } else {
          item.account = null
        }

        if (current) {
          current.dashboardItemName = item.dashboardItemName
          current.dashboardItemFavorite = item.dashboardItemFavorite
          current.userId = item.userId
          current.currencyBase = item.currencyBase
          current.currencyCode = item.currencyCode
          current.accountNumber = item.accountNumber
          current.accountNumberPublic = item.accountNumberPublic
          current.accountName = item.accountName
          current.dashboardItemOrder = item.dashboardItemOrder
          current.balance = item.balance
          current.realRate = item.realRate
          current.customerRate = item.customerRate
          current.currency = item.currency
          current.account = item.account
          current.history = item.history
        } else {
          this._dashboard.push(item)
        }
      })
    } else {
      this._dashboard = []
    }
  }

  @Mutation
  addHistory(value: IRatesHistory) {
    Vue.set(this._history, value.sell, value)
  }

  @Mutation
  setDashboardItem(request: IDashboardItemChangeRequest) {
    mergeDashboardItem(request)
  }

  @Mutation
  setDashboardDiscret(value: DiscretizationTypes) {
    this._dashboardDiscret = value
  }

  @Mutation
  setDashboardBalances(value: Array<number>) {
    this._dashboardBalances = value
  }

  @Mutation
  setLastTrans(value: Array<IOperationFull>) {
    this._lastTransList = value
  }

  @Mutation
  setGlobalPayMethods(value: Array<IPayMethod>) {
    value.forEach(item => {
      item.type = this._payMethodsVariantsIndex[item.payMethodTypeVariant] || PAY_TYPE_DEFAULT()
      item.payMethodType = item.type.payMethodType
      item.payMethodGlobal = true
    })
    this._globalPayMethods = value
  }

  @Mutation
  setUserPayMethods(value: Array<IPayMethod>) {
    value.forEach(item => {
      item.type = this._payMethodsVariantsIndex[item.payMethodTypeVariant] || PAY_TYPE_DEFAULT()
      item.payMethodType = item.type.payMethodType
      item.payMethodGlobal = false
      item.removing = false
      item.processing = false
    })
    this._userPayMethods = value
  }

  @Mutation
  setPayMethodsTypes(value: Array<IPayMethodType>) {
    const index: Record<string, IPayMethodType> = {}
    value.forEach(item => { index[item.payMethodTypeVariant] = item })
    this._payMethodsVariantsIndex = index
    this._payMethodsVariants = value
  }

  @Mutation
  startRemovingPayMethod(value: IPayMethod) {
    value.removing = true;
  }

  @Mutation
  stopRemovingPayMethod(value: IPayMethod) {
    value.removing = false;
  }

  @Mutation
  startProcessingPayMethod(value: IPayMethod) {
    value.processing = true;
  }

  @Mutation
  stopProcessingPayMethod(value: IPayMethod) {
    value.processing = false;
  }

  @Mutation
  storePayMethod(value: IPayMethod) {
    value.type = this._payMethodsVariantsIndex[value.payMethodTypeVariant] || PAY_TYPE_DEFAULT()
    value.payMethodType = value.type.payMethodType
    value.payMethodGlobal = false
    value.removing = false
    value.processing = false
    const i = this._userPayMethods.findIndex(i => i.payMethodTypeVariant === value.payMethodTypeVariant && i.payMethodNumber === value.payMethodNumber)
    if (i >= 0) {
      this._userPayMethods.splice(i, 1, value)
    } else {
      this._userPayMethods.push(value)
    }
  }

  @Mutation
  delPayMethod(value: IPayMethod) {
    const i = this._userPayMethods.findIndex(i => i.payMethodTypeVariant === value.payMethodTypeVariant && i.payMethodNumber === value.payMethodNumber)
    if (i >= 0) {
      this._userPayMethods.splice(i, 1)
    }
  }

  @Mutation
  addAccount(value: IAccount) {
    value.currency = this._currencyIndex[value.accountCurrency]
    value.accountBalAvailEq = value.accountBalAvail / value.currency.baseRate.realRate

    this._accounts.push(value)
    if (value.accountNumber) {
      Vue.set(this._accountIndex, value.accountNumber, value)
    }
  }

  @Mutation
  setCurrentOperation(value: IOperation | null) {
    this._currentOperation = value;
  }

  @Mutation
  setCurrentPreview(value: IDataPreview | null) {
    this._currentPreview = value || { visible: false };
  }

  // ---------------------------------------------------------------------- ACTIONS
  @Action({ rawError: true })
  async loadBaseData(user: IUser) {
    let response = await $ph.get('/users/current/dashboard?discret=' + this.dashboardDiscret)

    this.setCustomer(response.customer)
    let customerNumber = response.customer ? response.customer.customerNumber : 'SYSTEM'

    this.setCompany(response.company)
    this.setPerson(response.person)
    this.setIdentCurrent(response.identCurrent)
    this.setIdentNext(response.identNext)
    this.setIdentLevels(response.identLevels)
    this.setBaseRates(response.baseRates)
    this.setCurrencies(response.currencies)
    this.setBaseCurrency(currency(user.userBaseCurrency))
    this.setAccounts(response.accounts)
    this.setDashboardBalances(response.balances)
    this.setPayMethodsTypes(response.payMethodsTypes)
    this.setQuestionnaire(response.questionnaire)

    if (response.items) {
      for (const item of response.items) {
        item.history = null
        prepareAccount(item)
        if (item.currencyCode !== settings.company.companyBaseCurrency) {
          this.loadDashboardItemHistory(item)
        }
      };
    }
    this.setGlobalPayMethods(response.payMethodsGlobals)
    this.setDashboard(response.items)

    if (response.lastTransList) {
      let list: Array<IOperationFull> = response.lastTransList;
      list.forEach(item => {
        prepareOperation(customerNumber, this._currencyIndex, item)
      })
      this.setLastTrans(list);
    }
  }

  @Action({ rawError: true })
  async refreshBaseData(user: IUser) {
    let response = await $ph.get('/users/current/dashboard/mini')

    this.setCustomer(response.customer)
    let customerNumber = response.customer ? response.customer.customerNumber : 'SYSTEM'

    this.setCompany(response.company)
    this.setPerson(response.person)
    session.setUser(response.user)
    this.setIdentCurrent(response.identCurrent)
    this.setIdentNext(response.identNext)
    this.setIdentLevels(response.identLevels)
    this.setBaseRates(response.baseRates)
    this.setCurrencies(response.currencies)
    this.setBaseCurrency(currency(user.userBaseCurrency))
    this.setAccounts(response.accounts)
    this.setQuestionnaire(response.questionnaire)

    if (response.items) {
      for (const item of response.items) {
        item.history = null
        prepareAccount(item)
        if (item.currencyCode !== settings.company.companyBaseCurrency) {
          this.loadDashboardItemHistory(item)
        }
      };
    }

    this.setDashboard(response.items)

    if (response.lastTransList) {
      let list: Array<IOperationFull> = response.lastTransList;
      list.forEach(item => {
        prepareOperation(customerNumber, this._currencyIndex, item)
      })
      this.setLastTrans(list);
    }
  }

  @Action({ rawError: true })
  async findOperations(filter: IOperFilter) {
    if (filter.objectCreated && filter.objectCreated.from) {
      filter.objectCreated.from = new Date(filter.objectCreated.from).native(true)
    }

    if (filter.objectCreated && filter.objectCreated.till) {
      filter.objectCreated.till = new Date(filter.objectCreated.till).native(true)
    }

    if (filter.export) {
      await this.processReport({ url: '/operations/search/export/', format: ReportFormats.CSV, filter })
      filter.export = false;
    }

    this.setOperFilter(filter);

    if (!filter.export) {
      const result: ISearchResult<IOperationFull> = await $ph.post('/operations/search', filter);

      for (const item of result.items) {
        prepareOperation(this.customer!.customerNumber, this.currIndex, item)
      }

      if (filter.append) {
        this.addOperations(result);
      } else {
        this.setOperations(result);
      }
    }
  }

  @Action({ rawError: true })
  async findProcessing(filter: IProcessingFilter) {
    if (filter.export) {
      await this.processReport({ url: '/processing/requests/export/', format: ReportFormats.CSV, filter })
      filter.export = false;
    }

    this.setProcessingFilter(filter);

    if (!filter.export) {
      const result: ISearchResult<IProcessingRequest> = await $ph.post('/processing/requests/search', filter);

      for (const item of result.items) {
        prepareProcessing(this.currIndex, item)
      }

      if (filter.append) {
        this.addProcessing(result);
      } else {
        this.setProcessing(result);
      }
    }
  }

  @Action({ rawError: true })
  async startIdent(payload: IVerificationIdent): Promise<IVerification> {
    return $ph.post('/verifications/ident', payload);
  }

  @Action({ rawError: true })
  async completeIdent(payload: IIdentLevel): Promise<boolean> {
    await $ph.post('/verifications/ident/complete', payload);
    return true;
  }

  @Action({ rawError: true })
  async change2FA(request: IChange2FA): Promise<Array<number>> {
    return $ph.post('/users/2fa', request)
  }

  @Action({ rawError: true })
  async getCustomerProcessingForms(): Promise<Array<ICustomerProcessingForm>> {
    return $ph.get('/users/current/processing-forms')
  }

  @Action({ rawError: true })
  async loadDashboardItemHistory(item: IDashboardItem) {
    let history = this._history[item.currencyCode]
    if (!history) {
      history = await $ph.get('/currencies/rates/archive?buy=' + item.currencyBase + '&sell=' + item.currencyCode)
      this.addHistory(history)
    }
    this.setDashboardItem({ item, data: { history } })
  }

  @Action({ rawError: true })
  async storeDashboardItem(request: IDashboardItemChangeRequest) {
    const item: IDashboardItem = $ph.clone(request.item)
    mergeDashboardItem({ item, data: request.data })

    const changed: IDashboardItem = await $ph.post('/users/current/dashboard', item)
    this.setDashboardItem({ item: request.item, data: changed })
  }

  @Action({ rawError: true })
  async createFactor(request: IChange2FA): Promise<IMultifactor> {
    let data = await $ph.post('/users/factors', request);
    let factor: IMultifactor = {
      multiFactorId: data.multiFactorId,
      multiFactorNumber: data.multiFactorNumber,
      barCode: data.barCode,
      multiFactorMasterKey: data.multiFactorMasterKey,
      multiFactorProvider: data.multiFactorProvider,
      multiFactorSecret: data.multiFactorSecret,
      multiFactorState: data.multiFactorState,
    }
    return factor;
  }

  @Action({ rawError: true })
  async getOperationTypeByMode(value: IOperation): Promise<Array<IOperModeSettings>> {
    const list: Array<IOperModeSettings> = await $ph.post('/operations/modes/find', value)
    if (list) {
      list.forEach(i => {
        i.order = 0

        if (i.accountTypeCode && i.accountTypeCode !== '%') {
          i.order += 1
        }
        if (i.feeCode && i.feeCode !== '%') {
          i.order += 1
        }
        if (i.operCurrencyBuy && i.operCurrencyBuy !== '%') {
          i.order += 1
        }
        if (i.operCurrencySell && i.operCurrencySell !== '%') {
          i.order += 1
        }
        if (i.customerCountry && i.customerCountry !== '%') {
          i.order += 1
        }
        if (i.userProfileType && i.userProfileType !== '%') {
          i.order += 1
        }
        if (i.payMethodTypeVariant && i.payMethodTypeVariant !== '%') {
          i.order += 1
        }
      })

      list.sort((a, b) => b.order - a.order)
    }
    return list
  }

  @Action({ rawError: true })
  async getOperationTypeSettings(value: IOperation): Promise<IOperTypeSettings> {
    const result = await $ph.post('/operations/types/find', value)
    if (!result) {
      throw new AppError('SYS011', 'Invalid operation type: ' + value.operTypeCode, value.operTypeCode)
    }
    return result
  }

  @Action({ rawError: true })
  async getPayMethodSettings(value: IOperation): Promise<Array<IPayMethodSettings>> {
    return $ph.post('/operations/pay-methods/find', value)
  }

  @Action({ rawError: true })
  async refreshPayMethods(): Promise<Array<IPayMethod>> {
    const list = await $ph.get('/users/current/payments/methods')
    this.setUserPayMethods(list)
    return list;
  }

  @Action({ rawError: true })
  async putPayMethod(method: IPayMethod): Promise<IPayMethod> {
    method = await $ph.post('/users/current/payments/methods', method)
    this.storePayMethod(method)
    return method
  }

  @Action({ rawError: true })
  async preparePayMethod(method: IPayMethod): Promise<IPayMethod> {
    return $ph.post('/users/current/payments/methods?mode=prepare', method)
  }

  @Action({ rawError: true })
  async changePayMethod(payload: Replace<IPayMethod>): Promise<IPayMethod | null> {
    this.startProcessingPayMethod(payload.current)
    try {
      const list: Array<IPayMethod> = await $ph.patch('/users/current/payments/methods', payload.updated)
      this.setUserPayMethods(list)
      return list.find(i => i.payMethodTypeVariant === payload.current.payMethodTypeVariant && i.payMethodNumber === payload.current.payMethodNumber) || null
    } finally {
      this.stopProcessingPayMethod(payload.current)
    }
  }

  @Action({ rawError: true })
  async removePayMethod(method: IPayMethod): Promise<any> {
    this.startRemovingPayMethod(method)
    try {
      const list: Array<IPayMethod> = await $ph.delete('/users/current/payments/methods/' + method.userPayMethodId)
      this.setUserPayMethods(list)
    } finally {
      this.stopRemovingPayMethod(method)
    }
  }

  @Action({ rawError: true })
  async getRate(request: IRateRequest): Promise<IRate> {
    return $ph.get('/currencies/rates', request)
  }

  @Action({ rawError: true })
  async getFee(oper: IOperation): Promise<IOperationFull> {
    return $ph.post('/operations/fees', oper)
  }

  @Action({ rawError: true })
  async makeOperation(oper: IOperation): Promise<IOperationFull> {
    return $ph.post('/operations', oper)
  }

  @Action({ rawError: true })
  async getOperation(ref: string): Promise<IOperationFull> {
    return $ph.get('/operations/list/' + ref)
  }

  @Action({ rawError: true })
  async createCardToken(): Promise<ICardToken> {
    return $ph.post('/users/current/payments/cards/token/' + session.user.userLanguage, window.location.origin)
  }

  @Action({ rawError: true })
  async createProcessingCardToken(tokenType: CardTokenTypes): Promise<ICardToken> {
    return $ph.post('/processing/cards/token/' + session.user.userLanguage + '/' + tokenType, window.location.origin)
  }

  @Action({ rawError: true })
  async getCardToken(token: string): Promise<ICardToken> {
    return $ph.get('/processing/cards/token/' + token)
  }

  @Action({ rawError: true })
  async createProcessingRequest(request: IProcessingRequestBase): Promise<IProcessingRequest> {
    return $ph.post('/processing/requests/items', request)
  }

  @Action({ rawError: true })
  async getProcessingRequest(token: string): Promise<IProcessingRequest> {
    return $ph.get('/processing/requests/items/' + token)
  }

  @Action({ rawError: true })
  async appendAccount(item: IDashboardItem): Promise<IAccount> {
    let response: IAccount = await $ph.post('/accounts', item.currencyCode)
    prepareAccount(response)
    this.addAccount(response)
    return response
  }

  @Action({ rawError: true })
  async createNetworkAddress(address: INetworkAddress): Promise<IAccount> {
    const response: IAccount = await $ph.post('/accounts/' + encodeURIComponent(address.accountNumber) + '/networks/' + address.networkId, {});
    prepareAccount(response);
    return response;
  }

  @Action({ rawError: true })
  async getFinanceNetworks(currency: string): Promise<Array<INetworkSettings>> {
    const nets: Array<INetworkSettings> = await $ph.get('/accounts/networks/' + currency)
    return nets || []
  }

  @Action({ rawError: true })
  async getReport(reportId: string): Promise<IReport> {
    return $ph.get('/reports/items/' + reportId);
  }

  @Action({ rawError: true })
  async processReport(payload: { url: string, format: ReportFormats, filter: any }) {
    let report = await $ph.binary(payload.url + payload.format, 'POST', {}, payload.filter);

    while (report.reportState !== ProcessStates.Done && report.reportState !== ProcessStates.Failed) {
      await $ph.wait(1000)
      report = await this.getReport(report.reportId || '')
      if (report === null) {
        throw new AppError('REP001', 'Report not found!')
      }
    }

    if (report.reportAttachment) {
      const url = window.location;
      const attachment = url.protocol + '//' + url.host + '/files/' + report.reportAttachment.substring(0, 3) + '/' + report.reportAttachment
      $ph.downloadByLink(attachment)
    } else if (report.reportState === ProcessStates.Done) {
      report.reportState = ProcessStates.Failed
      report.reportDetails = 'No attachment found!'
    }

    if (report.reportState === ProcessStates.Failed) {
      throw new AppError('REP002', report.reportDetails)
    }
  }

  @Action({ rawError: true })
  async printStatement(filter: IStatementFilter) {
    await this.processReport({ url: '/accounts/statement/export/', format: ReportFormats.PDF, filter })
  }

  @Action({ rawError: true })
  async getStatementFull(filter: IStatementFilter): Promise<IStatement | null> {
    if (filter.export) {
      await this.processReport({ url: '/accounts/statement/export/', format: ReportFormats.CSV, filter })
      return null;
    } else {
      const data = await $ph.post('/accounts/statement', filter);
      const result: IStatement = JSON.parse(data)

      let i: number = 1;
      for (const item of result.transactions) {
        item.rowNumber = i++;
        prepareTransaction(item, this.currIndex)
      }

      return result
    }
  }

  @Action({ rawError: true })
  async getStatement(filter: IStatementFilter): Promise<Array<ITransaction> | null> {
    const result = await this.getStatementFull(filter);
    if (result != null) {
      let currency = filter.bookCurrency || settings.company.companyBaseCurrency;

      result.transactions.splice(0, 0, {
        currency: this.currIndex[currency] || $ph.clone(CURRENCY_DEFAULT),
        rowNumber: ROW_NUMBER_START_BAL,
        tranAccountCtNumber: '',
        tranAccountCtPublic: '',
        tranAccountDtNumber: '',
        tranAccountDtPublic: '',
        tranTurnCt: 0,
        tranTurnDt: 0,
        tranDtAmount: 0,
        tranCallerRef: '',
        transDocNumber: '',
        tranReference: '',
        tranCorrName: '',
        tranCorrCustomer: '',
        tranDtCurrency: result.accountCurrency,
        tranDescr: $ph.i18n('transactions.StartBalance'),
        tranId: 0,
        tranBalance: result.startBalance,
        transCorrType: result.startBalance < 0 ? 'DT' : 'CT',
        transDate: new Date(),
        transTemplateCode: '',
        transLinkedGate: null,
        tranState: ProcessStates.Done,
      });

      result.transactions.push({
        currency: this.currIndex[currency] || $ph.clone(CURRENCY_DEFAULT),
        rowNumber: ROW_NUMBER_TURNS,
        tranAccountCtNumber: '',
        tranAccountCtPublic: '',
        tranAccountDtNumber: '',
        tranAccountDtPublic: '',
        tranTurnCt: result.turnoverCt,
        tranTurnDt: result.turnoverDt,
        tranDtAmount: 0,
        tranCallerRef: '',
        transDocNumber: '',
        tranReference: '',
        tranCorrName: '',
        tranCorrCustomer: '',
        tranDtCurrency: result.accountCurrency,
        tranDescr: $ph.i18n('transactions.Turnovers'),
        tranId: 0,
        tranBalance: 0,
        transCorrType: null,
        transDate: new Date(),
        transTemplateCode: '',
        transLinkedGate: null,
        tranState: ProcessStates.Done,
      });

      result.transactions.push({
        currency: this.currIndex[currency] || $ph.clone(CURRENCY_DEFAULT),
        rowNumber: ROW_NUMBER_END_BAL,
        tranAccountCtNumber: '',
        tranAccountCtPublic: '',
        tranAccountDtNumber: '',
        tranAccountDtPublic: '',
        tranTurnCt: 0,
        tranTurnDt: 0,
        tranDtAmount: 0,
        tranCallerRef: '',
        transDocNumber: '',
        tranReference: '',
        tranCorrName: '',
        tranCorrCustomer: '',
        tranDtCurrency: result.accountCurrency,
        tranDescr: $ph.i18n('transactions.EndBalance'),
        tranId: 0,
        tranBalance: result.endBalance,
        transCorrType: result.endBalance < 0 ? 'DT' : 'CT',
        transDate: new Date(),
        transTemplateCode: '',
        transLinkedGate: null,
        tranState: ProcessStates.Done,
      });

      return result.transactions;
    }
    return null
  }

  @Action({ rawError: true })
  async findReports(filter: IReportFilter) {
    this.setReportFilter(filter);
    const result: ISearchResult<IOperationFull> = await $ph.post('/reports/search', filter);

    if (filter.append) {
      this.addOperations(result);
    } else {
      this.setOperations(result);
    }
  }
}

function mergeDashboardItem(request: IDashboardItemChangeRequest) {
  const data: any = request.data;
  if (data['history'] !== undefined) {
    request.item.history = data.history
  }

  if (data['account'] !== undefined) {
    request.item.account = data.account
    request.item.accountNumber = data.account.accountNumber || ''
    request.item.accountNumberPublic = data.account.accountNumberPublic
    request.item.accountName = data.account.accountName
    request.item.balance = data.account.accountBalAvail
  }

  if (data['dashboardItemFavorite'] !== undefined) {
    request.item.dashboardItemFavorite = data.dashboardItemFavorite
  }

  if (data['dashboardItemId'] !== undefined) {
    request.item.dashboardItemId = data.dashboardItemId
  }

  if (data['dashboardItemName'] !== undefined) {
    request.item.dashboardItemName = data.dashboardItemName
  }
}
